import { Grid } from "theme-ui"
import PostTitleOrnament from "./PostTitleOrnament"

import * as sass from "./headings.module.scss"

const PostTitle = ({ title, children }) => {
  return (
    <>
      <Grid as="header" variant={"postTitle"}>
        <PostTitleOrnament />
        <h1
          className={sass.titlePost}
          sx={{
            variant: "text.title",
            marginLeft: "0.3rem"
          }}
        >
          {title}
        </h1>
        {children}
      </Grid>
    </>
  )
}

export default PostTitle
