import { useStaticQuery, graphql } from "gatsby"

export const useSerialMetadata = () => {
  const { site } = useStaticQuery(graphql`
    query SerialMetadataHook {
      site {
        siteMetadata {
          serial {
            author {
              name
              link
            }
            email {
              address
              domain
            }
            copyright {
              title
              year
              license {
                name
                link
              }
            }
            tipjar
            twitter
          }
        }
      }
    }
  `)

  return site.siteMetadata
}
