import * as sass from "./links.module.scss"

const TipJarLink = ({ link, label }) => {
  return (
    <div
      className={sass.navItem}
      sx={{
        variant: "layout.navLink"
        //background: () => alpha("peacock", 0.3)
      }}
    >
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        sx={{ variant: "links.nav" }}
      >
        {label}
      </a>
    </div>
  )
}

export default TipJarLink

TipJarLink.defaultProps = {
  label: "Donate"
}
