const FirstLine = ({ children }) => {
  return (
    <span
      sx={{
        variant: "text.firstLine"
      }}
    >
      {children}
    </span>
  )
}

export default FirstLine
