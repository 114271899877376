import * as sass from "./headings.module.scss"

const TitleOrnament = ({ children }) => {
  return (
    <>
      <div
        className={sass.titleOrnament}
        aria-hidden="true"
        sx={{
          variant: "text.ornaments",
          marginTop: ["-0.7rem", null, null, "-0.65rem", "-0.6rem"],
          gridArea: "brleft",
          marginRight: "1rem"
          //alignSelf: "center"
        }}
      >
        {"\uE0A4"}
      </div>
      {children}
      <div
        className={sass.titleOrnament}
        aria-hidden="true"
        sx={{
          variant: "text.ornaments",
          marginTop: ["-0.7rem", null, null, "-0.65rem", "-0.6rem"],
          gridArea: "brright",
          marginLeft: "1rem"
          //alignSelf: "center"
        }}
      >
        {"\uE0A5"}
      </div>
    </>
  )
}

export default TitleOrnament
