import { Grid } from "theme-ui"
import { HeaderNav } from "./nav"
import { HomeIcon, HomeLink, NavLink } from "./links"

const Masthead = ({ siteTitle, menu }) => {
  return (
    <Grid
      id="masthead"
      as="header"
      variant={"nav"}
      sx={{
        padding: "1.5rem 0 0.5rem",
        gridTemplateRows: "3rem",
        gridTemplateColumns: ["min-content auto", "repeat(2, auto)"],
        gridColumnGap: ["0.5rem", 0],
        gridRowGap: 0,
        gridTemplateAreas: ["'title nav'", "'title nav'"],
        "&:after": {
          variant: "decor.navigation",
          marginTop: "3rem" //"calc(3rem + 1px)" //["32px", "35px", "38px", "41px", "44px"]
        }
      }}
    >
      <HomeLink siteTitle={siteTitle} />
      <HomeIcon />
      <HeaderNav>
        {menu.map((item) => (
          <NavLink key={item.label} link={item.link} label={item.label} />
        ))}
      </HeaderNav>
    </Grid>
  )
}

export default Masthead
