import { graphql, Link } from "gatsby"
import { Grid } from "theme-ui"

import * as sass from "./links.module.scss"

const SceneLinks = ({ scenes: { edges, totalCount } }) => {
  const remainder = totalCount % 3
  const rows = Math.ceil(totalCount / 3)

  const cellStyle = {
    variant: "layout.sceneLinksCell",
    ...(remainder === 1 && {
      "&:nth-last-of-type(1)": { gridColumn: "span 6" }
    }),
    ...(remainder === 2 && {
      "&:nth-last-of-type(-n + 2)": { gridColumn: "span 3" },
      "&:nth-last-of-type(2)": { justifySelf: "end" },
      "&:nth-last-of-type(1)": { justifySelf: "start" }
    })
  }

  return (
    <Grid
      variant="sceneLinks"
      sx={{
        gridTemplateRows: `repeat(${rows}, 2rem)`
      }}
    >
      {edges.map(({ node: scene }) => (
        <div sx={cellStyle} key={scene.order}>
          <Link
            to={scene.slug}
            className={sass.sceneLink}
            sx={{
              variant: "links.body",
              fontFamily: "body",
              fontWeight: "bold",
              display: "inline-block"
            }}
          >
            scene {scene.scene}
          </Link>
        </div>
      ))}
    </Grid>
  )
}

export default SceneLinks

export const sceneLinksQuery = graphql`
  fragment SceneLinksFragment on ProsePost {
    id
    scene
    slug
    order
  }
`
