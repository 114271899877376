import { Link } from "gatsby"

import * as sass from "./links.module.scss"

const LandingLink = ({ link, label }) => {
  return (
    <div className={sass.landingLink} sx={{ height: "3rem" }}>
      <Link
        to={link}
        partiallyActive={false}
        sx={{
          variant: "links.landing",

          "&:before": {
            variant: "links.landing.hoverDeco",
            marginRight: "15px",
            content: "'\uE0A6'",
            transform: "translateX(30px)"
          },
          "&:after": {
            variant: "links.landing.hoverDeco",
            marginLeft: "15px",
            content: "'\uE0A7'",
            transform: "translateX(-30px)"
          },
          "&:hover, &:focus-visible, &:active": {
            "&:before, &:after": {
              opacity: "1",
              transform: "translateX(0)"
            }
          }
        }}
      >
        {label}
      </Link>
    </div>
  )
}

export default LandingLink
