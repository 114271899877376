import { Link } from "gatsby"
import { capitalize as cap } from "lodash"

const ChapterOl = ({ volume, chapters }) => {
  return (
    <li
      sx={{
        counterIncrement: "top-level",
        fontFamily: "body",
        fontWeight: "body",
        fontFeatureSettings: "'kern' 1, 'liga' 1, 'dlig' 1, 'case' 1, 'smcp' 1",
        "&:before": {
          content: "counter(top-level, upper-roman) '. '",
          variant: "text.listMarker"
        }
      }}
    >
      <Link to={`/story/${volume}/`} sx={{ variant: "links.toc" }}>
        {cap(volume)}
      </Link>
      <ol sx={{ paddingTop: "1rem", marginLeft: "4rem" }}>
        {chapters.map(({ node }) => {
          const { id, chapter, slug, title } = node
          return (
            <li
              key={id}
              data-order={chapter}
              sx={{
                paddingBottom: "1rem",
                fontFeatureSettings:
                  "'kern' 1, 'liga' 1, 'dlig' 1,'case' 1, 'onum' 1, 'tnum' 1, 'smcp' 0",
                "&:before": {
                  content: "attr(data-order) '. '",
                  variant: "text.listMarker"
                }
              }}
            >
              <Link
                to={slug}
                sx={{ variant: "links.toc", fontStyle: "italic" }}
              >
                {title}
              </Link>
            </li>
          )
        })}
      </ol>
    </li>
  )
}

export default ChapterOl
