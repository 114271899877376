import { Flex } from "theme-ui"

const FooterNav = ({ children }) => {
  return (
    <>
      <Flex
        as="nav"
        variant={"layout.menu"}
        aria-label="Footer"
        sx={{
          flexFlow: "row wrap-reverse",
          alignSelf: "start",
          rowGap: "0"
        }}
      >
        {children}
      </Flex>
    </>
  )
}

export default FooterNav
