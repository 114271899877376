import { Grid } from "theme-ui"

import * as sass from "./headings.module.scss"

const AlphaOrnament = () => {
  return (
    <div
      className={sass.ornamentAlpha}
      aria-hidden="true"
      sx={{
        variant: "text.ornaments",
        marginRight: "0.3rem",
        alignSelf: "start"
      }}
    >
      {"\uE09D"}
    </div>
  )
}

const BetaOrnament = () => {
  return (
    <div
      className={sass.ornamentBeta}
      aria-hidden="true"
      sx={{
        variant: "text.ornaments",
        marginRight: "0.5rem",
        alignSelf: "start"
      }}
    >
      {"\uE09D"}
    </div>
  )
}

export const Alpha = ({ children }) => {
  return (
    <Grid as="div" variant={"hierarchicalTitle"}>
      <AlphaOrnament />
      <h1 className={sass.titleAlpha} sx={{ variant: "text.heading" }}>
        {children}
      </h1>
    </Grid>
  )
}

export const Beta = ({ children }) => {
  return (
    <Grid as="div" variant={"hierarchicalTitle"}>
      <BetaOrnament />
      <h2 className={sass.titleBeta} sx={{ variant: "text.heading" }}>
        {children}
      </h2>
    </Grid>
  )
}
