import * as sass from "./links.module.scss"

const ContactLink = ({ address, domain }) => {
  return (
    <div
      className={sass.navItem}
      sx={{
        variant: "layout.navLink",
        "&:after": { content: "''" }
        //background: () => alpha("peacock", 0.3)
      }}
    >
      <a
        href="#mailgo"
        data-address={address}
        data-domain={domain}
        aria-label="Contact details"
        sx={{ variant: "links.nav" }}
      >
        Contact
      </a>
    </div>
  )
}

export default ContactLink
