import { Link } from "gatsby"

const ListLink = ({ link, label }) => {
  return (
    <li
      sx={{
        fontFamily: "body",
        fontWeight: "body",
        fontFeatureSettings: "'kern' 1, 'liga' 1, 'dlig' 1, 'case' 1, 'smcp' 1",
        "&:before": { content: "'\ue0a0\u00A0'", variant: "text.listMarker" }
      }}
    >
      <Link to={link} sx={{ variant: "links.toc" }}>
        {label}
      </Link>
    </li>
  )
}

export default ListLink
