const measure = {
  breakpoints: ["640px", "800px", "1024px", "1440px"],
  // space: ["0.3rem", "0.5rem", "1rem", "2rem", "3rem", "5rem", "6rem"],
  sizes: {
    max: {
      xs: "100%",
      sm: "94%",
      md: "94%",
      lg: "1100px",
      xl: "1300px"
    },
    reg: {
      xs: "490px",
      sm: "550px",
      md: "600px",
      lg: "680px",
      xl: "750px"
    },
    container: "100%"
  },
  layout: {
    container: {
      width: "100%",
      maxWidth: "container"
    },
    regular: {
      width: ["max.sm"],
      maxWidth: ["reg.xs", "reg.sm", "reg.md", "reg.lg", "reg.xl"]
    },
    wide: {
      width: ["max.xs", "max.sm"],
      maxWidth: ["max.xs", "max.sm", "max.md", "max.lg", "max.xl"]
    },
    sticky: {
      maxWidth: "100%",
      zIndex: "sticky",
      position: "sticky",
      bottom: 0,
      marginTop: ["2rem", null, null, null, "1rem"],
      marginBottom: ["2rem", null, null, null, "3rem"]
    },
    menu: {
      gridArea: "nav",
      justifySelf: "end",
      justifyContent: "flex-end",
      alignItems: "baseline",
      marginRight: "1rem"
    },
    navLink: {
      fontFamily: "navigation",
      fontWeight: "navigation",
      textTransform: "lowercase",
      fontFeatureSettings: "'kern' 1, 'liga' 1, 'dlig' 1, 'case' 1, 'smcp' 1",
      "&:after": {
        color: "decor",
        WebkitFontSmoothing: "auto",
        MozOsxFontSmoothing: "auto"
      }
    },
    sceneLinksCell: {
      gridColumn: "span 2",
      borderLeft: (theme) => `2px solid ${theme.colors.accent}`,
      paddingX: "1rem",
      "&:nth-of-type(3n+1)": {
        borderLeft: "2px solid transparent"
      }
    }
  },
  grids: {
    nav: {
      width: ["max.xs", "max.sm"],
      maxWidth: ["max.xs", "max.sm", "max.md", "max.lg", "max.xl"],
      mx: "auto",
      my: "0"
    },
    landing: {
      gridTemplateColumns: "auto",
      gridTemplateRows: "repeat(4, 3rem)",
      gridGap: "0",
      placeItems: "center",
      placeSelf: "center"
    },
    sceneLinks: {
      margin: "0 auto 1rem",
      gridTemplateColumns: "repeat(6, max-content)",
      rowGap: "1rem",
      columnGap: "0",
      placeContent: "center",
      placeItems: "center",
      fontFeatureSettings: "'kern' 1, 'liga' 1, 'onum' 1, 'tnum' 1"
    },
    pagination: {
      width: "100%",
      gridTemplateRows: "4rem",
      alignContent: "center",
      backgroundColor: "background", //"rgba(16, 176, 228, 0.3)",
      padding: "0 0 1rem",
      position: "relative",
      zIndex: "sticky",
      transition: "changeMode",
      "&:before": {
        variant: "decor.pagination"
      },
      "&:after": {
        variant: "decor.pagination",
        paddingBottom: "4rem",
        zIndex: "-1 !important"
      }
    },
    pageLinks: {
      gridTemplateRows: "auto",
      gridColumnGap: "1rem",
      gridRowGap: "0",
      alignSelf: "center",
      alignContent: "center"
    },
    pageLinksNext: {
      variant: "grids.pageLinks",
      justifySelf: "end",
      paddingRight: "1rem"
    },
    pageLinksPrev: {
      variant: "grids.pageLinks",
      justifySelf: "start",
      paddingLeft: "1rem"
    },
    title: {
      gridTemplateColumns: "repeat(3, auto)",
      textAlign: "center",
      placeItems: "center",
      justifyContent: "center",
      alignContent: "center",
      gridGap: "0",
      mt: "4rem"
    },
    pageTitle: {
      variant: "grids.title",
      gridTemplateRows: "auto",
      gridTemplateAreas: "'brleft title brright'",
      mb: "1rem"
    },
    chapterTitle: {
      variant: "grids.title",
      gridTemplateRows: "repeat(2, auto)",
      gridTemplateAreas: "'number number number' 'brleft title brright'",
      mb: "0"
    },
    tagPageTitle: {
      variant: "grids.chapterTitle",
      gridTemplateAreas: "'brleft title brright' 'number number number'",
      mb: "1rem"
    },
    sectionTitle: {
      variant: "grids.pageTitle",
      mt: "1rem",
      mb: "0"
    },
    sceneTitle: {
      display: "grid",
      gridTemplateRows: ["repeat(2, 2rem)", "2rem"],
      gridTemplateColumns: ["auto", "repeat(2, auto)"],
      gridTemplateAreas: ["'volume' 'title'", "'volume title'"],
      alignItems: "center",
      gridRowGap: "0",
      marginBottom: "4rem"
    },
    postTitle: {
      width: ["90%"],
      maxWidth: ["reg.xs", "reg.sm", "reg.md", "reg.lg", "reg.xl"],
      gridTemplateColumns: "min-content 1fr",
      gridTemplateRows: "repeat(3, auto)",
      gridTemplateAreas: "'deco title' 'null meta' 'tags tags' ",
      gridGap: "0",
      mx: "auto",
      mt: ["5rem", null, null, null, "6rem"]
    },
    hierarchicalTitle: {
      variant: "grids.postTitle",
      width: "100%",
      gridTemplateRows: "auto",
      gridTemplateAreas: "'deco title'",
      mt: "0",
      pt: "1rem"
    }
  }
}

export default measure
