import * as sass from "./text.module.scss"

const Bsd = () => {
  return (
    <div
      dir="rtl"
      className={sass.bsd}
      sx={{
        variant: "text.bsd",
        position: "absolute",
        top: "0",
        right: ["0.5rem", "1rem"]
      }}
    >
      בס״ד
    </div>
  )
}

export default Bsd
