import { Grid } from "theme-ui"
import { FeedNav, FooterNav } from "./nav"
import { ContactLink, FeedLink, NavLink, TipJarLink } from "./links"
import { ColorToggle } from "./buttons"
import { Bsd, Copyright } from "./text"

const Colophon = ({
  siteUrl,
  menu,
  serial: { author, email, copyright, tipjar }
}) => {
  return (
    <>
      <Grid
        id="colophon"
        as="footer"
        variant={"nav"}
        sx={{
          flexShrink: "0",
          padding: "0 0 1rem",
          gridTemplateColumns: ["auto", null, "auto max-content"],
          gridTemplateRows: ["repeat(3, auto)", null, "repeat(2, auto)"],
          gridTemplateAreas: [
            "'nav' 'copyright' 'feed'",
            null,
            "'copyright nav' 'feed feed'"
          ],
          gridColumnGap: "2rem",
          gridRowGap: "0",
          "&:before": {
            variant: "decor.navigation",
            marginTop: "-1px"
          }
        }}
      >
        <FooterNav>
          <ColorToggle />
          <TipJarLink link={tipjar} />
          {menu.map((item) => (
            <NavLink key={item.label} link={item.link} label={item.label} />
          ))}
          <ContactLink address={email.address} domain={email.domain} />
        </FooterNav>
        <Copyright siteUrl={siteUrl} author={author} copyright={copyright} />
        <FeedNav>
          <FeedLink link="/story-rss.xml" label="Story" />
          <FeedLink link="/blog-rss.xml" label="Blog" />
        </FeedNav>
      </Grid>
      <Bsd />
    </>
  )
}

export default Colophon
