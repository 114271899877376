import { useColorMode } from "theme-ui"

import * as sass from "./buttons.module.scss"

const ColorToggle = () => {
  const [colorMode, setColorMode] = useColorMode()

  return (
    <div
      className={sass.navItem}
      sx={{
        "&:after": {
          color: "decor",
          fontFamily: "navigation",
          fontWeight: "navigation",
          WebkitFontSmoothing: "auto",
          MozOsxFontSmoothing: "auto"
        }
      }}
    >
      <button
        className={sass.navButton}
        sx={{
          variant: "buttons.link",
          fontFamily: "navigation",
          fontWeight: "navigation",
          textTransform: "lowercase",
          fontFeatureSettings:
            "'kern' 1, 'liga' 1, 'dlig' 1, 'case' 1, 'smcp' 1",
          color: "accent",
          marginX: "0.2rem",
          padding: "0 0.3rem 0.5rem",
          lineHeight: "2rem",
          display: "inline-block",
          "&:focus-visible, &:active, &:hover": {
            color: "hover.nav",
            textDecoration: "underline"
          }
        }}
        onClick={(e) => {
          const next = colorMode === "dark" ? "light" : "dark"
          setColorMode(next)
        }}
      >
        {colorMode === "dark" ? "Lite" : "Dark"} Mode
      </button>
    </div>
  )
}

export default ColorToggle
