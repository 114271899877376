import Tippy from "@tippyjs/react"
import sanitizeHtml from "sanitize-html"
import { Close } from "theme-ui"
import { useRef } from "react"
import { toNumber } from "lodash"
import { inlinePositioning } from "tippy.js"
import { hideOnEsc, hideOnPopperBlur } from "../../utils/tippy-plugins"

import * as sass from "./text.module.scss"

const FnParagraph = ({ ...props }) => {
  return (
    <p
      //className={sass.footnote}
      sx={{
        variant: "text.body",
        padding: "0 0 1rem",
        margin: "0",
        textIndent: "0",
        fontSize: ["14px", null, "16px", "18px"],
        lineHeight: ["18px", null, "20px", "24px"],
        em: {
          fontStyle: "italic",
          lineHeight: "100%"
        }
      }}
      {...props}
    />
  )
}

const Fn = ({ children, content, mark, maxwidth }) => {
  const tippyRef = useRef()
  const closeTippy = () => tippyRef.current.hide()

  return (
    <span sx={{ display: "inline-block", textIndent: "0" }}>
      {children}
      <Tippy
        onCreate={(tippy) => (tippyRef.current = tippy)}
        allowHTML={true}
        arrow={false}
        maxWidth={toNumber(maxwidth)}
        trigger="click"
        touch={true}
        placement="auto"
        interactive={true}
        plugins={[inlinePositioning, hideOnEsc, hideOnPopperBlur]}
        inlinePositioning={true}
        hideOnEsc={true}
        hideOnPopperBlur={true}
        sx={{
          background: "highlight",
          color: "text",
          borderRadius: "link",
          border: (theme) => `1px solid ${theme.colors.muted}`
        }}
        content={
          <>
            <Close
              title={null}
              aria-label="close footnote"
              onClick={closeTippy}
            />
            <div sx={{ padding: "2.5rem 2.5rem 1.5rem", hyphens: "auto" }}>
              <span
                sx={{
                  float: "left",
                  textAlign: "center",
                  fontSize: "2rem",
                  fontWeight: "bold",
                  margin: "-0.8rem 1rem 0",
                  color: "primary",
                  fontFeatureSettings: "'kern' 1, 'tnum' 1, 'onum' 1, 'case' 1",
                  display: "block"
                }}
              >
                {mark}
              </span>
              {content.map((elem, i) => (
                <FnParagraph
                  key={`fnPara${i + 1}`}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(elem)
                  }}
                />
              ))}
            </div>
          </>
        }
      >
        <button sx={{ variant: "buttons.footnote" }}>[{mark}]</button>
      </Tippy>
    </span>
  )
}

Fn.defaultProps = {
  maxwidth: "400"
  // mark: "†"
}

export default Fn
