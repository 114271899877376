const base = {
  radii: {
    none: "0",
    link: "0.3rem"
  },
  shadows: {
    focus: "0 0 0 2px rgba(16, 176, 228, 0.8)"
  },
  zIndices: {
    sticky: 100,
    menu: 998,
    toggle: 999,
    line: 999
  },
  fonts: {
    body: "Mamontov",
    heading: "IsabelUnicase",
    monospace: "VictorMono",
    dropcap: "Ivory",
    navigation: "Isabel",
    bsd: "FiraGO"
  },
  fontWeights: {
    body: 300,
    bold: 400,
    heading: 700,
    navigation: 700,
    monospace: 500
  },
  transitions: {
    changeMode: "background-color 250ms ease-in, color",
    landingLink: "transform 0.6s ease-out, opacity 0.2s"
  },
  decor: {
    line: {
      zIndex: "line",
      content: "''",
      position: "absolute",
      overflow: "visible",
      borderBottom: "2px solid",
      borderBottomColor: "decor"
    },
    navigation: {
      variant: "decor.line",
      width: ["max.xs", "max.sm"],
      maxWidth: ["max.xs", "max.sm", "max.md", "max.lg", "max.xl"]
    },
    pagination: {
      variant: "decor.line",
      width: "100%"
    }
  }
}

export default base
