import * as sass from "./text.module.scss"

const QuoteGraf = ({ children }) => {
  return (
    <p className={sass.quoteGraf} sx={{ variant: "text.paragraph" }}>
      {children}
    </p>
  )
}

export default QuoteGraf
