import { Flex } from "theme-ui"
// import { alpha } from "@theme-ui/color"

const HeaderNav = ({ children }) => {
  return (
    <>
      <Flex
        as="nav"
        variant={"layout.menu"}
        aria-label="Primary"
        sx={{
          flexFlow: "row"
        }}
      >
        {children}
      </Flex>
    </>
  )
}

export default HeaderNav
