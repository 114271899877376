import { Link } from "gatsby"

import * as sass from "./links.module.scss"

const HomeLink = ({ siteTitle }) => {
  return (
    <>
      <div
        className={sass.siteTitle}
        sx={{
          display: ["none", "inline-block"],
          fontFamily: "navigation",
          fontWeight: "navigation",
          fontStyle: "italic",
          gridArea: "title",
          marginLeft: "1rem",
          fontFeatureSettings: "'kern' 1, 'liga' 1, 'dlig' 1, 'case' 1",
          zIndex: 999
        }}
      >
        <Link to="/" sx={{ variant: "links.nav", color: "accent" }}>
          {siteTitle}
        </Link>
      </div>
    </>
  )
}

export default HomeLink
