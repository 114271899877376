import { Themed } from "theme-ui"

import * as sass from "./text.module.scss"

const Copyright = ({ siteUrl, author, copyright }) => {
  return (
    <section
      sx={{
        variant: "text.monospace",
        gridArea: "copyright",
        justifySelf: ["center", null, "start"],
        marginLeft: ["0", null, "1rem"],
        paddingX: ["0.5rem", null, "0"],
        textAlign: ["center", null, "left"]
      }}
    >
      <h2 sx={{ variant: "text.hidden" }}>Copyright Information</h2>
      <p className={sass.copyrightGraf}>
        <Themed.a property="dct:title" rel="cc:attributionURL" href={siteUrl}>
          {copyright.title}
        </Themed.a>
        {"\u00A0"}©{"\u00A0"}
        {copyright.year},{" "}
        <Themed.a
          rel="me cc:attributionURL dct:creator"
          property="cc:attributionName"
          href={author.link}
        >
          {author.name}
        </Themed.a>
        ; licensed under{" "}
        <Themed.a
          href={copyright.license.link}
          target="_blank"
          rel="license noopener noreferrer"
          sx={{
            whiteSpace: "nowrap"
          }}
        >
          {copyright.license.name}
        </Themed.a>
      </p>
    </section>
  )
}

export default Copyright
