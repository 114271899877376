import { Grid } from "theme-ui"
import { LandingLink } from "../links"

const LandingNav = ({ chapter, navigation }) => {
  return (
    <Grid
      as="nav"
      variant={"landing"}
      role="navigation"
      aria-label="Landing page"
    >
      <LandingLink link={chapter} label="Latest Chapter" />
      {navigation.map((item) => (
        <LandingLink link={item.link} label={item.label} key={item.label} />
      ))}
    </Grid>
  )
}

export default LandingNav
