import { Link } from "gatsby"
import { kebabCase } from "lodash"

const ArchiveLink = ({ link, label, aria }) => {
  const ariaLabel = `${kebabCase(label)}Aria`
  return (
    <div sx={{ placeSelf: "center" }}>
      <Link
        to={link}
        aria-labelledby={ariaLabel}
        sx={{ variant: "links.pagination" }}
      >
        {label}
      </Link>
      <span id={ariaLabel} sx={{ variant: "text.hidden" }}>
        {aria}
      </span>
    </div>
  )
}

export default ArchiveLink
