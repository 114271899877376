import { Grid } from "theme-ui"
import TitleOrnament from "./TitleOrnament"

import * as sass from "./headings.module.scss"

const SectionTitle = ({ title }) => {
  return (
    <Grid as="header" variant={"sectionTitle"}>
      <TitleOrnament>
        <h2 className={sass.titleFont} sx={{ variant: "text.title" }}>
          {title}
        </h2>
      </TitleOrnament>
    </Grid>
  )
}

export default SectionTitle
