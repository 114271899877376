import * as sass from "./lists.module.scss"

const Ul = ({ children }) => {
  return (
    <ul
      className={sass.genericList}
      sx={{
        variant: "text.paragraph",
        paddingBottom: "1rem",
        li: {
          marginLeft: "2rem",
          //paddingBottom: "1rem",
          "&:before": {
            position: "absolute",
            content: "'\ue09b\u00A0'",
            color: "primary",
            marginLeft: "-2rem",
            height: "2rem",
            fontWeight: "bold"
          }
        }
      }}
    >
      {children}
    </ul>
  )
}

export default Ul
