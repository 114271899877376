import { Flex } from "theme-ui"

import Main from "../components/Main"
import Masthead from "../components/Masthead"
import Colophon from "../components/Colophon"

import { useSiteMetadata } from "../hooks/use-site-metadata"
import { useSerialMetadata } from "../hooks/use-serial-metadata"
import { useSiteNavigation } from "../hooks/use-site-navigation"

const Layout = ({ children, location, ...props }) => {
  const { siteTitle, siteUrl } = useSiteMetadata()
  const { serial } = useSerialMetadata()
  const { headerMenu, footerMenu } = useSiteNavigation()

  return (
    <Flex
      id="site"
      sx={{ minHeight: "100vh", width: "100%", flexDirection: "column" }}
    >
      {location.pathname !== "/" && (
        <Masthead siteTitle={siteTitle} menu={headerMenu} />
      )}
      <Main>{children}</Main>
      <Colophon menu={footerMenu} serial={serial} siteUrl={siteUrl} />
    </Flex>
  )
}

export default Layout
