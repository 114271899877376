import { Grid } from "theme-ui"
import TitleOrnament from "./TitleOrnament"

import * as sass from "./headings.module.scss"

const ArticleTitle = ({ number, title, epigraph }) => {
  return (
    <Grid as="header" variant={number === null ? "pageTitle" : "chapterTitle"}>
      {number && (
        <p
          className={epigraph ? sass.bookNumber : sass.titleNumber}
          sx={{
            variant: "text.number",
            "&:before, &:after": {
              content: "'\u2014'",
              WebkitFontSmoothing: "antialiased",
              MozOsxFontSmoothing: "grayscale"
            }
          }}
        >
          {number}
        </p>
      )}
      <TitleOrnament>
        <h1 className={sass.titleFont} sx={{ variant: "text.title" }}>
          {number && (
            <span sx={{ variant: "text.hidden" }}>Chapter {number}:</span>
          )}{" "}
          {title}
        </h1>
      </TitleOrnament>
    </Grid>
  )
}

ArticleTitle.defaultProps = {
  number: null,
  epigraph: false
}

export default ArticleTitle
