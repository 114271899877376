import { Container } from "theme-ui"

const FeedNav = ({ children }) => {
  return (
    <Container
      aria-label="RSS Feed Links"
      sx={{ gridArea: "feed", justifySelf: "center", textAlign: "center" }}
    >
      {children}
    </Container>
  )
}

export default FeedNav
