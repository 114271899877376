import * as sass from "./headings.module.scss"

const PostTitleOrnament = () => {
  return (
    <>
      <div
        className={sass.titleOrnament}
        aria-hidden="true"
        sx={{
          variant: "text.ornaments",
          marginRight: "0.3rem",
          alignSelf: "center"
        }}
      >
        {"\uE0A0"}
      </div>
    </>
  )
}

export default PostTitleOrnament
