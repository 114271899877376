import { alpha, tint } from "@theme-ui/color"

const swatch = {
  peacock: "#10b0e4",
  seance: "#982d6f",
  obsidian: {
    body: "#120a23",
    note: "#2c254c" // abyss
  },
  opal: {
    body: "#d3c8ee",
    note: "#bcafe0"
  },
  smoke: {
    dark: "#c0aef0",
    lite: "#3e3755"
  },
  phantom: {
    dark: "#aa93e5",
    lite: "#3c2e75"
  },
  perfume: {
    dark: "#a488f2",
    lite: "#5c47a1"
  },
  grimoire: {
    dark: "#866ccb",
    lite: "#3d2c82"
  },
  inkwell: {
    dark: "#2c207b",
    lite: "#debfff"
  },
  garland: {
    dark: "#c26199",
    lite: "#962b6c"
  },
  mirage: {
    dark: "#a33877",
    lite: "#780253"
  }
}

const palette = {
  colors: {
    text: swatch.smoke.dark,
    tag: swatch.inkwell.dark,
    background: swatch.obsidian.body,
    primary: swatch.garland.dark, //swatch.perfume.dark,
    secondary: swatch.phantom.dark, //swatch.garland.dark,
    accent: swatch.perfume.dark, // swatch.phantom.dark,
    muted: "#664fab", // formerly arrow-dark and border
    //modal: swatch.obsidian.note,
    highlight: swatch.obsidian.note, //swatch.peacock,
    blockquote: "rgba(44, 37, 76, 0.3)",
    decor: swatch.seance,
    peacock: swatch.peacock,
    hover: {
      nav: swatch.grimoire.dark,
      link: swatch.mirage.dark,
      toggle: swatch.garland.dark
    },
    modes: {
      light: {
        text: swatch.smoke.lite,
        tag: swatch.inkwell.lite,
        background: swatch.opal.body,
        primary: swatch.garland.lite, //swatch.perfume.lite,
        secondary: swatch.phantom.lite, //swatch.garland.lite,
        accent: swatch.perfume.lite, //swatch.phantom.lite,
        muted: swatch.grimoire.dark, // formerly arrow-dark and border
        // modal: swatch.opal.note,
        highlight: swatch.opal.note,
        blockquote: "rgba(188, 175, 224, 0.3)",
        hover: {
          nav: swatch.grimoire.lite,
          link: swatch.mirage.lite,
          toggle: swatch.garland.dark
        }
      }
    }
  }
}

export default palette
