import { Link } from "gatsby"
// import { alpha } from "@theme-ui/color"

import * as sass from "./links.module.scss"

const NavLink = ({ label, link }) => {
  return (
    <div
      className={sass.navItem}
      sx={{
        variant: "layout.navLink"
        //background: () => alpha("peacock", 0.3)
      }}
    >
      <Link to={link} partiallyActive={false} sx={{ variant: "links.nav" }}>
        {label}
      </Link>
    </div>
  )
}

export default NavLink
