// extracted by mini-css-extract-plugin
export var blogItemList = "headings-module--blogItemList--1U2IP";
export var bookNumber = "headings-module--bookNumber--VXdcX";
export var ornamentAlpha = "headings-module--ornamentAlpha--+iD+p";
export var ornamentBeta = "headings-module--ornamentBeta--q8q24";
export var titleAlpha = "headings-module--titleAlpha--9E39F";
export var titleBeta = "headings-module--titleBeta--+Ro6Y";
export var titleFont = "headings-module--titleFont--2EvLX";
export var titleNumber = "headings-module--titleNumber--1DdfY";
export var titleOrnament = "headings-module--titleOrnament--8lwLj";
export var titlePost = "headings-module--titlePost--kFmRT";