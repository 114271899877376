import * as sass from "./lists.module.scss"

const NotFoundUl = ({ children }) => {
  return (
    <ul
      className={sass.genericList}
      sx={{
        variant: "text.paragraph",
        paddingBottom: "1rem",
        li: {
          marginLeft: "4rem",
          //paddingBottom: "1rem",
          "&:before": {
            variant: "text.listMarker",
            position: "absolute",
            content: "'\ue09b\u00A0'",
            marginLeft: "-2rem",
            height: "2rem"
          }
        }
      }}
    >
      {children}
    </ul>
  )
}

export default NotFoundUl
