import { Grid } from "theme-ui"

const Nmb = ({ children }) => {
  return (
    <span
      sx={{
        fontFeatureSettings:
          "'kern' 1, 'liga' 1, 'dlig' 1, 'case' 1, 'onum' 1, 'tnum' 1"
      }}
    >
      {children}
    </span>
  )
}

const SceneTitle = ({ volume, chapter, scene, title }) => {
  return (
    <Grid as="header" variant="sceneTitle">
      <div
        sx={{
          gridArea: "volume",
          marginLeft: "1rem",
          justifySelf: ["center", "start"]
        }}
      >
        <span
          sx={{
            variant: "text.sceneTitle"
          }}
        >
          {volume}
        </span>
      </div>

      <h1
        sx={{
          variant: "text.sceneTitle",
          gridArea: "title",
          justifySelf: ["center", "end"],
          marginRight: "1rem"
        }}
      >
        <Nmb>{chapter} |</Nmb> {title}, scene <Nmb>{scene}</Nmb>
      </h1>
    </Grid>
  )
}

export default SceneTitle
