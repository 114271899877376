import { Grid } from "theme-ui"
import TitleOrnament from "./TitleOrnament"

import * as sass from "./headings.module.scss"

const TagPageTitle = ({ number, title }) => {
  return (
    <Grid as="header" variant={"tagPageTitle"}>
      <TitleOrnament>
        <h1 className={sass.titleFont} sx={{ variant: "text.title" }}>
          {number && (
            <span sx={{ variant: "text.hidden" }}>{number} tagged</span>
          )}
          {title}
        </h1>
      </TitleOrnament>
      <p
        className={sass.titleNumber}
        sx={{
          variant: "text.number"
        }}
      >
        {number}
      </p>
    </Grid>
  )
}

TagPageTitle.defaultProps = {
  number: null
}

export default TagPageTitle
