import {
  // Bdg,
  BlockQuote,
  EndChapter,
  DropCap,
  Fn,
  FirstLine,
  Paragraph,
  QuoteGraf
} from "../components/text"

import { Ul } from "../components/lists"

import { Alpha, Beta } from "../components/headings"

const components = {
  DropCap,
  FirstLine,
  EndChapter,
  QuoteGraf,
  h1: Alpha,
  h2: Beta,
  p: Paragraph,
  ul: Ul,
  BlockQuote,
  Fn
}

export default components
