import * as sass from "./links.module.scss"

import FeedIcon from "../../images/svg/rssAlt.inline.svg"

const FeedLink = ({ label, link }) => {
  return (
    <div
      className={sass.feedItem}
      sx={{ variant: "text.monospace", display: "inline-block" }}
    >
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        sx={{
          variant: "links.feed"
        }}
      >
        <FeedIcon
          sx={{
            height: "1.5rem",
            width: "1.5rem",
            padding: "0.15rem",
            ".primary": { fill: "primary" },
            ".secondary": { fill: "accent" }
          }}
        />
        {label}
      </a>
    </div>
  )
}

export default FeedLink
