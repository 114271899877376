import { Link } from "gatsby"

import RoseIcon from "../../images/svg/home.inline.svg"

const HomeIcon = () => {
  return (
    <Link
      to="/"
      sx={{
        display: ["inline-block", "none"],
        marginLeft: "1rem",
        marginBottom: "3px",
        fill: "primary",
        "&:focus-visible": { outline: "transparent", boxShadow: "focus" },
        "&:focus-visible, &:active, &:hover": {
          borderRadius: "4rem",
          fill: "hover.link"
        }
      }}
    >
      <RoseIcon sx={{ height: "calc(3rem - 3px)", padding: "0.2rem" }} />
    </Link>
  )
}

export default HomeIcon
