import * as sass from "./text.module.scss"

const Paragraph = ({ children }) => {
  return (
    <p className={sass.paragraph} sx={{ variant: "text.paragraph" }}>
      {children}
    </p>
  )
}

export default Paragraph
