import * as sass from "./text.module.scss"

const BlockQuote = ({ children, byline, source }) => {
  return (
    <figure sx={{ py: "1rem" }}>
      <blockquote
        sx={{
          mx: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: ["center", "left"],
          fontStyle: "italic",
          width: ["25rem", "100%"]
        }}
      >
        {children}
      </blockquote>
      <figcaption
        className={sass.byline}
        sx={{
          fontFamily: "navigation",
          fontWeight: "navigation",
          color: "primary",
          fontFeatureSettings: "'kern' 1, 'liga' 1, 'clig' 1, 'dlig' 1",
          textAlign: "right"
        }}
      >
        —{byline}, <cite sx={{ fontStyle: "italic" }}>{source}</cite>
      </figcaption>
    </figure>
  )
}

export default BlockQuote
