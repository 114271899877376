const typography = {
  text: {
    body: {
      fontFamily: "body",
      fontWeight: "body",
      color: "text"
    },
    paragraph: {
      variant: "text.body",
      hyphens: "auto",
      fontFeatureSettings: "'kern' 1, 'liga' 1, 'onum' 1, 'pnum' 1, 'case' 1",
      WebkitFontSmoothing: "auto",
      MozOsxFontSmoothing: "auto",
      "&:first-of-type": {
        textIndent: "0"
      }
    },
    monospace: {
      fontFamily: "monospace",
      fontWeight: "monospace",
      hyphens: "none",
      fontFeatureSettings: "'kern' 1, 'liga' 1, 'clig' 1, 'dlig' 1, 'case' 1",
      color: "text"
    },
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      color: "secondary"
    },
    title: {
      variant: "text.heading",
      gridArea: "title"
    },
    ivory: {
      fontFamily: "dropcap",
      fontWeight: 400
    },
    dropcap: {
      variant: "text.ivory",
      display: "block",
      float: "left",
      marginRight: "0.1em",
      marginTop: "-0.12em",
      fontSize: "6.5rem",
      lineHeight: "6rem",
      textTransform: "uppercase",
      color: "primary",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale"
    },
    firstLine: {
      variant: "text.ivory",
      color: "accent",
      textTransform: "lowercase"
    },
    number: {
      variant: "text.body",
      color: "primary",
      gridArea: "number",
      fontFeatureSettings: "'kern' 1, 'liga' 1, 'onum' 1, 'tnum' 1"
    },
    ornaments: {
      fontFamily: "body",
      fontWeight: "bold",
      color: "primary",
      alignSelf: "center"
    },
    listMarker: {
      fontFamily: "body",
      fontWeight: "bold",
      color: "accent"
    },
    bsd: {
      fontFamily: "bsd",
      fontWeight: "body",
      color: "primary"
    },
    sceneTitle: {
      fontFamily: "body",
      fontWeight: "bold",
      color: "secondary",
      fontFeatureSettings: "'kern' 1, 'liga' 1, 'dlig' 1, 'case' 1, 'smcp' 1"
    },
    hidden: {
      clip: "rect(1px, 1px, 1px, 1px)",
      clipPath: "inset(50%)",
      height: "1px",
      width: "1px",
      margin: " -1px",
      overflow: "hidden",
      padding: "0",
      position: "absolute"
    }
  },
  links: {
    base: {
      textDecoration: "none",
      borderRadius: "link",
      cursor: "pointer",
      "&:focus-visible": {
        outline: "transparent",
        boxShadow: "focus"
      }
    },
    body: {
      variant: "links.base",
      fontWeight: "bold",
      color: "primary",
      "&:hover, &:active, &:focus-visible": {
        color: "hover.link",
        textDecoration: "underline"
      }
    },
    toc: {
      variant: "links.body",
      fontWeight: "body",
      WebkitFontSmoothing: "auto",
      MozOsxFontSmoothing: "auto"
    },
    nav: {
      variant: "links.base",
      marginX: "0.2rem",
      padding: "0 0.3rem 0.5rem",
      color: "accent",
      display: "inline-block",
      "&:hover, &:active, &:focus-visible": {
        color: "hover.nav",
        textDecoration: "underline"
      }
    },
    landing: {
      variant: "links.body",
      fontFamily: "heading",
      fontWeight: "heading",
      display: "inline-block",
      hoverDeco: {
        variant: "text.ornaments",
        display: "inline-block",
        color: "accent",
        transition: "landingLink",
        opacity: "0"
      }
    },
    pagination: {
      fontFamily: "heading",
      fontWeight: "heading",
      textTransform: "uppercase",
      fontFeatureSettings: "'kern' 1, 'liga' 1, 'dlig' 1, 'case' 1",
      variant: "links.base",
      color: "accent",
      textAlign: "center",
      ".primary": { fill: "accent" },
      ".secondary": { fill: "muted" },
      "&:hover, &:active, &:focus-visible": {
        color: "hover.nav",
        textDecoration: "underline",
        ".primary, .secondary": {
          fill: "primary"
        }
      }
    },
    paginationGrid: {
      variant: "links.pagination",
      display: "grid",
      gridTemplateColumns: "repeat(2, auto)",
      gridTemplateRows: "2rem",
      alignContent: "center"
    },
    tag: {
      variant: "links.base",
      fontFamily: "monospace",
      fontWeight: 700,
      color: "tag",
      fontStyle: "italic",
      background: "secondary",
      border: (theme) => `2px solid ${theme.colors.accent}`,
      padding: "0.6rem 0.8rem",
      "&:hover, &:active, &:focus": {
        textDecoration: "underline"
      }
    },
    feed: {
      variant: "links.nav",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      padding: "0 0.3rem",
      textTransform: "uppercase",
      fontWeight: "monospace",
      "&:hover, &:active, &:focus-visible": {
        color: "hover.nav",
        textDecoration: "underline",
        ".primary": { fill: "hover.link" },
        ".secondary": { fill: "hover.nav" }
      }
    }
  },
  buttons: {
    close: {
      variant: "buttons.link",
      height: "2rem",
      width: "2rem",
      position: "absolute",
      top: "2px",
      right: "2px",
      color: "accent",
      "&:hover, &:active, &:focus-visible": {
        color: "primary"
      }
    },
    link: {
      background: "none !important",
      border: "none",
      marginY: "0",
      padding: "0 !important",
      textDecoration: "none",
      cursor: "pointer",
      borderRadius: "link",
      "&:focus-visible": {
        outline: "transparent",
        boxShadow: "focus"
      }
    },
    footnote: {
      variant: "buttons.link",
      fontFamily: "body",
      fontWeight: "bold",
      fontSize: "1rem",
      padding: "2px",
      fontFeatureSettings: "'kern' 1, 'liga' 1, 'tnum' 1, 'lnum' 1",
      position: "relative",
      top: ["-0.3em", "-0.5em"],
      color: "primary",
      "&:active, &:hover, &:focus-visible": {
        color: "hover.link"
      }
    }
  },
  icons: {
    rose: {
      fill: "primary"
    },
    arrow: {
      width: "2rem",
      height: "2rem"
    }
  },
  badges: {
    changeLog: {
      color: "primary",
      fontWeight: 400,
      lineHeight: "100%"
    }
  },
  styles: {
    root: {
      textRendering: "optimizeLegibility",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      transition: "changeMode"
    },
    body: {
      lineHeight: "2rem",
      height: "100vh"
    },
    p: {
      fontFamily: "body"
    },
    a: {
      variant: "links.body"
    },
    em: {
      fontStyle: "italic",
      lineHeight: "100%"
    },
    strong: {
      fontWeight: 400,
      lineHeight: "100%"
    }
  }
}

export default typography
