import { useStaticQuery, graphql } from "gatsby"

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(graphql`
    query SiteMetadataHook {
      site {
        siteMetadata {
          siteTitle
          siteTitleTemplate {
            blog
            post
            page
            home
          }
          siteDescription
          siteUrl
          keywords
        }
      }
    }
  `)

  return site.siteMetadata
}
