import * as sass from "./text.module.scss"

const DropCap = ({ children }) => {
  return (
    <p
      className={sass.paragraph}
      sx={{
        variant: "text.paragraph",
        textIndent: "0",
        "&:first-letter": {
          variant: "text.dropcap"
        }
      }}
    >
      {children}
    </p>
  )
}

export default DropCap
