import RoseIcon from "../../images/svg/rose.inline.svg"

const EndChapter = () => {
  return (
    <RoseIcon
      sx={{
        variant: "icons.rose",
        height: [
          "1.25rem",
          "1.29411764706rem",
          "1.333333333333rem",
          "1.3rem",
          "1.27272727273rem"
        ]
      }}
    />
  )
}

export default EndChapter
