import * as sass from "./text.module.scss"

const ChapterSummary = ({ text }) => {
  return (
    <p
      className={sass.chapterSummary}
      sx={{
        variant: "text.body",
        fontStyle: "italic",
        color: "text",
        textAlign: "center",
        fontFeatureSettings:
          "'kern' 1, 'case' 1, 'liga' 1, 'dlig' 1, 'onum' 1, 'tnum' 1"
      }}
    >
      {text}
    </p>
  )
}

export default ChapterSummary
