import { Link } from "gatsby"

const TagLink = ({ label, link }) => {
  return (
    <div
      sx={{
        borderRadius: "link",
        height: "2rem"
      }}
    >
      <Link
        to={link}
        sx={{
          variant: "links.tag"
        }}
      >
        {label}
      </Link>
    </div>
  )
}

export default TagLink
